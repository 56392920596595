<template>
    <aside class="aside is-placed-left is-expanded">
        <div class="aside-tools">
            <div class="aside-tools-label">
                <span><b>Envía Ya!</b> ShopConnect</span>
            </div>
        </div>
        <div class="menu is-menu-main">
            <p class="menu-label">General</p>
            <ul class="menu-list">
                <li>
                    <a href="index.html" class="is-active router-link-active has-icon">
                        <span class="icon"><i class="mdi mdi-desktop-mac"></i></span>
                        <span class="menu-item-label">Dashboard</span>
                    </a>
                </li>
            </ul>
            <p class="menu-label">Tiendas</p>
            <ul class="menu-list">
                <li>
                    <a href="javascript:;" class="has-icon">
                        <span class="icon"><i class="mdi mdi-square-edit-outline"></i></span>
                        <span class="menu-item-label">Pedidos</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:;" @click="goTo('/shops')" class="has-icon">
                        <span class="icon"><i class="mdi mdi-square-edit-outline"></i></span>
                        <span class="menu-item-label">Mis tiendas</span>
                    </a>
                </li>
            </ul>
            <p class="menu-label">Envíos</p>
            <ul class="menu-list">
                <li>
                    <a href="javascript:;"  class="has-icon">
                        <span class="icon"><i class="mdi mdi-github-circle"></i></span>
                        <span class="menu-item-label">Mis envíos</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:;"   @click="goTo('/packages')" class="has-icon">
                        <span class="icon"><i class="mdi mdi-github-circle"></i></span>
                        <span class="menu-item-label">Mis empaques</span>
                    </a>
                </li>
            </ul>
            <p class="menu-label">Configuración</p>
            <ul class="menu-list">
                <li>
                    <a href="javascript:;" @click="goTo('/config')" class="has-icon">
                        <span class="icon"><i class="mdi mdi-github-circle"></i></span>
                        <span class="menu-item-label">Envía Ya!</span>
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>

export default {
    name:"AsideEshop",
    methods:{
        goTo(path){
            this.$router.push(path);
        }
    },
    components:{
        
    }
}

</script>