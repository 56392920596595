<template>
    <nav id="navbar-main" class="navbar is-fixed-top">
        <div class="navbar-brand">
        </div>
        <div class="navbar-brand is-right">
            <a class="navbar-item is-hidden-desktop jb-navbar-menu-toggle" data-target="navbar-menu">
                <span class="icon"><i class="mdi mdi-dots-vertical"></i></span>
            </a>
        </div>
        <div class="navbar-menu fadeIn animated faster" id="navbar-menu">
            <div class="navbar-end">

                <div class="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
                    <a class="navbar-link is-arrowless">
                        <div class="is-user-avatar">
                            <img src="https://avatars.dicebear.com/v2/initials/john-doe.svg">
                        </div>
                        <div class="is-user-name"><span></span></div>
                        <span class="icon"><i class="mdi mdi-chevron-down"></i></span>
                    </a>
                </div>
                <a title="Log out" class="navbar-item">
                    <font-awesome-icon :icon="['fas', 'fa-arrow-right-from-bracket']" />
                </a>
            </div>
        </div>
    </nav>
</template>

<script>


export default {
    name:"NavEshop",
    components:{
        
    }
}
</script>