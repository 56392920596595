import { createStore } from 'vuex';

export default createStore({
    state: {
        isAuthenticated: false,
        email:"",
        token:"",

    },
    mutations: {
        SET_IS_AUTHENTICATED(state,val){
            state.isAuthenticated = val
        },

        SET_EMAIL(state,val){
            state.email = val
        },

        SET_TOKEN(state,val){
            state.token = val
        },
    },
    actions: {

        setIsAuthenticated(context,val){
            context.commit('SET_IS_AUTHENTICATED',val);
        },

        setEmail(context,val){
            context.commit('SET_EMAIL',val);
        },

        setToken(context,val){
            context.commit('SET_TOKEN',val);
        },

    }
     
    
})