<template>
    <div class="card has-table has-mobile-sort-spaced">
      <header class="card-header">
        <p class="card-header-title is-flex is-justify-content-space-between	">
        
          Pedidos
          <button @click="sync()" type="button" class="button is-warning">
          <span v-if="syncing">Sincronizando...</span>
          <span v-else>Sincronizar</span>
          </button>
        </p>
        <a href="#" class="card-header-icon">
          <span class="icon"><i class="mdi mdi-reload"></i></span>
        </a>
      </header>
      <div class="card-content">
        <div class="b-table has-pagination">
          <div class="table-wrapper has-mobile-cards">
           
            <table class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
              <thead>
              <tr> 
                <th>Pedido</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Articulos</th>
                <th>Envío</th>
                <th>Estatus</th>
                <th >Acciones</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                    <td>{{ order.ml_order_id }}</td>
                    <td>{{ order.ml_date_created }}</td>
                    <td>{{ order.ml_buyer_nickname }}</td>
                    <td>{{ order.ml_order_items }}</td>
                    <td>{{ order.ml_tags }}</td>
                    <td>{{ order.ml_order_status }}</td>
                    <td>
                      <button @click="goTo('/shops/'+this.shop_id+'/orders/'+order.id)" class="button is-small">Ver Pedido</button>
                    </td>
                </tr>
              </tbody>
            </table>
            <vue-awesome-paginate
              :total-items="totalItems"
              :items-per-page="itemsPerPage"
              :max-pages-shown="maxPageShown"
              v-model="currentPage"
              :on-click="onClickHandler"
            />




          </div>

        </div>
      </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name:"ShopOrdersPage",
    async created(){
      
        const shopId = this.$route.params.shop_id
        this.shop_id = shopId
        await this.fetchOrders()
        

    },
    data() {
        return {
          syncing:false,
          shop_id:"",
          orders: [],
          totalItems:0,
          itemsPerPage:50,
          maxPageShown:5,
          currentPage:1,
          
        };
    },
    methods:{
        async sync(){
          this.syncing = true;

          const url = process.env.VUE_APP_API_BASE_URL+'/api/mercadolibre/orders/sync?shop_id='+this.shop_id

          const headersObj = {
            headers: {
                "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                "Content-Type": 'application/json'
            }
          }

          try{

            await axios.get(url,headersObj)
            this.fetchOrders();
            this.syncing = false;
            
          }catch(error){

            console.error(error.response.data.message)

          }

        },
        async fetchOrders(){

          const url = process.env.VUE_APP_API_BASE_URL+'/api/mercadolibre/orders?shop_id='+this.shop_id+'&page='+this.currentPage

          const headersObj = {
            headers: {
                "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                "Content-Type": 'application/json'
            }
          }

          try{

            const response = await axios.get(url,headersObj)
            const responseData = response.data

            this.orders = responseData.data
            this.totalItems=responseData.total
            this.currentPage=responseData.current_page
              
              
            }catch(error){

              console.error(error.response.data.message)

            }

        },
        goTo(path){
            this.$router.push(path);
        },
        formatOrderItem(order_items){
          return order_items.map(item => {
            const itemName = item.item.title;
            const itemQuantity = item.quantity;
            return `${itemQuantity}x ${itemName}`;
          }).join('\n');
        },
        formatTags(tags){
          return tags.map(tag => {
            return `<span class="tag is-info">${tag}</span>`
          }).join('\n');

        },
        async onClickHandler(){

          await this.fetchOrders()

         
        }
    },
    components:{
      
    }
}

</script>

<style scoped> 



.table tbody > tr > td{
  text-align: left;
  vertical-align: middle;
}




</style>