<template>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <span class="icon"><i class="mdi mdi-ballot"></i></span>
          <img src="./../../assets/ml.png" style="width: 50px;"> {{mlOrder.id}}
        </p>
      </header>
      <div class="card-content">
        <div class="notification is-warning has-text-left"  v-for="(warning, index) in rateWarnings" :key="index">
            <button class="delete" @click="removeWarning(index)"></button>
            {{ warning }}
        </div>
        <div class="notification is-danger has-text-left"  v-for="(error, index) in rateErrors" :key="index">
            <button class="delete" @click="removeErrors(index,'rateErrors')"></button>
            {{ error }}
        </div>
        <div class="notification is-danger has-text-left"  v-for="(error, index) in shipmentErrors" :key="index">
            <button class="delete" @click="removeErrors(index,'shipmentErrors')"></button>
            {{ error }}
        </div>
        
        <div class="columns">
            <div class="column is-two-thirds">
                <nav class="panel" style="height: 100%;">
                    <p class="panel-heading has-text-left">
                        Artículos
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                        <thead>
                            <tr> 
                                <th>Cnt.</th>
                                <th>SKU</th>
                                <th>Nombre</th>
                                <th>Dimensiones</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in mlOrder.order_items" :key="index">
                              <td class="has-text-left">{{ item.quantity }}</td>  
                              <td class="has-text-left">{{ item.item.seller_sku }}</td>  
                              <td class="has-text-left">{{ item.item.title }}</td> 
                              <td class="has-text-left" v-if="item.package == null" >
                                <button type="button" @click="launchPackageModal(item)" class="button is-small is-danger">Crear empaque</button>
                              </td>
                              <td class="has-text-left" v-else>
                                {{ formatDimensions(item) }}
                              </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </nav>
            </div>
            <div class="column">
                <nav class="panel"  style="height: 100%;" v-if="mlOrder.enviaya == null">
                    <p class="panel-heading has-text-left">
                    Envia Ya
                    </p>
                    <div class="block p-4">
                        
                        <div class="select is-rounded is-fullwidth">
                        <select v-model="rateId" @change="setRate">
                            
                            <option value="">Seleccionar Servicio</option>
                            <option v-for="(rate, index) in rates" :key="index" :value="index">
                              {{ rate.carrier }} |  {{ rate.carrier_service_name }} | ( {{ rate.total_amount }} MXN )
                            </option>
                        </select>
                        
                    </div>
                    </div>
                    <div class="block pt-0 pl-4 pb-4 pr-4" style="display: flex; justify-content: center;">
                        <button  class="button is-success" @click="createShipment()" v-if="rates.length > 0">
                            <span v-if="creating">Creando...</span>
                            <span v-else>Crear envío</span>
                        </button>
                        <button  class="button is-warning ml-1" @click="rateShipping()">
                            <span v-if="rating">Cotizando...</span>
                            <span v-else>Cotizar</span>
                        </button>
                    </div>
                </nav>
                <nav class="panel"  style="height: 100%;" v-else>
                    <p class="panel-heading has-text-left">
                    Envia Ya
                    </p>
                    <div class="block p-4">
                        <table class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                            <tbody>
                                <tr>
                                    <td class="has-text-left">Estatus</td>
                                    <td class="has-text-left">{{ mlOrder.enviaya.shipment_status}}</td>
                                </tr>
                                <tr>
                                    <td class="has-text-left"> Paqueteria</td>
                                    <td class="has-text-left">{{ mlOrder.enviaya.carrier}}</td>
                                </tr>
                                <tr>
                                    <td class="has-text-left"> No. Rastreo</td>
                                    <td class="has-text-left">{{ mlOrder.enviaya.carrier_shipment_number}}</td>
                                </tr>
                                <tr>
                                    <td class="has-text-left"> Shiphuk ID</td>
                                    <td class="has-text-left">{{ mlOrder.enviaya.enviaya_shipment_number}}</td>
                                </tr>
                                <tr>
                                    <td class="has-text-left"> Etiqueta</td>
                                    <td class="has-text-left"><a target="_blank" :href="mlOrder.enviaya.label_share_link" class="button is-danger is-small">Descargar</a></td>
                                </tr>
                   
                             
                            </tbody>
                        </table>
                    </div>
                   
                </nav>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <nav class="panel" style="height: 100%;">
                    <p class="panel-heading has-text-left">
                        Origen
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                        <tbody>
                            <tr>
                                <td class="has-text-left">Nombre</td>
                                <td class="has-text-left">{{ origin_direction.full_name}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left">Compañia</td>
                                <td class="has-text-left">{{ origin_direction.company}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Correo electrónico</td>
                                <td class="has-text-left">{{ origin_direction.email}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Télefono</td>
                                <td class="has-text-left">{{ origin_direction.phone}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Código postal</td>
                                <td class="has-text-left">{{ origin_direction.postal_code}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Dirección</td>
                                <td class="has-text-left">{{ origin_direction.direction_1}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Referencias</td>
                                <td class="has-text-left">{{ origin_direction.direction_2}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Ciudad</td>
                                <td class="has-text-left">{{ origin_direction.city}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Estado</td>
                                <td class="has-text-left">{{ this.getStateNameByIso(origin_direction.state_code) }}</td>
                            </tr>            
         
                        </tbody>
                    </table>
                </nav>
            </div>
            <div class="column">
                <nav class="panel" style="height: 100%;">
                    <p class="panel-heading has-text-left">
                        Destino
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                        <tbody>
                            <tr>
                                <td class="has-text-left">Nombre</td>
                                <td class="has-text-left">{{ destination_direction.full_name}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Télefono</td>
                                <td class="has-text-left">{{ destination_direction.phone}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Pais</td>
                                <td class="has-text-left">{{ destination_direction.country_code}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Código postal</td>
                                <td class="has-text-left">{{ destination_direction.postal_code}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Dirección</td>
                                <td class="has-text-left">{{ destination_direction.direction_1}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Ciudad</td>
                                <td class="has-text-left">{{ destination_direction.city}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Estado</td>
                                <td class="has-text-left">{{ destination_direction.state}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Colonia</td>
                                <td class="has-text-left">{{ destination_direction.neighborhood}}</td>
                            </tr>
                        </tbody>
                    </table>
                </nav>

            </div>
        </div>
      </div>
      <div class="modal" v-if="showPackageModal" >
        <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Crear Empaque</p>
                <button class="delete" aria-label="close" @click="() => {this.showPackageModal=false}"></button>
                </header>
                <form method="post"  @submit.prevent="createPackage()">
                <section class="modal-card-body">
                   
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                        <label class="label">Nombre</label>
                        </div>
                        <div class="field-body">
                        <div class="field">
                            <div class="control">
                            <input class="input" type="text" v-model="pName" required readonly>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                        <label class="label">SKU</label>
                        </div>
                        <div class="field-body">
                        <div class="field">
                            <div class="control">
                            <input class="input" type="text" v-model="pSku" required readonly>
                            </div>
            
                        </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-text-left	">
                        <div class="field-label"><label class="label">Largo (cm)</label></div>
                        <div class="field-body">
                        <div class="field">
                            <div class="control">
                            <input class="input" type="text" v-model="pLength" required>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-text-left	">
                        <div class="field-label"><label class="label">Alto (cm)</label></div>
                        <div class="field-body">
                        <div class="field">
                            <div class="control">
                            <input class="input" type="text" v-model="pHeight" required>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-text-left	">
                        <div class="field-label"><label class="label">Ancho (cm)</label></div>
                        <div class="field-body">
                        <div class="field">
                            <div class="control">
                            <input class="input" type="text" v-model="pWidth" required>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-text-left	">
                        <div class="field-label"><label class="label">Peso (kg)</label></div>
                        <div class="field-body">
                        <div class="field">
                            <div class="control">
                            <input class="input" type="text" v-model="pWeight" required>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success">Guardar</button>
                    <button class="button" @click="() => {this.showPackageModal=false}">Cancel</button>
                </footer>
                </form>
            </div>
        </div>
    </div>
  </template>

<script>

import axios from 'axios';

export default {
    async created() {

        const shop_id = this.$route.params.shop_id
        
        const order_id = this.$route.params.order_id
        this.order_id = order_id

        const url = process.env.VUE_APP_API_BASE_URL+'/api/mercadolibre/orders/'+order_id
       

        const headersObj = {
            headers: {
                "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                "Content-Type": 'application/json'
            }
        }
        const url2 = process.env.VUE_APP_API_BASE_URL+'/api/shops/'+shop_id
        const response2 = await axios.get(url2,headersObj)
        
        this.shop = response2.data
  
        if(this.shop.config.origin_address_source == "1" || typeof this.shop.config.origin_address_source == 'undefined' ){
            
            if(typeof this.shop.me.first_name != 'undefined' && typeof this.shop.me.last_name != 'undefined'){
                this.origin_direction.full_name = ( this.shop.me.first_name != null) ? this.shop.me.first_name : ""
                this.origin_direction.full_name +=  ( this.shop.me.last_name != null) ? ` ${this.shop.me.last_name}` : ""
            }

            if(typeof this.shop.me.company.brand_name != 'undefined'){
                this.origin_direction.company = ( this.shop.me.company.brand_name != null) ? this.shop.me.company.brand_name : ""
            }

            if(typeof this.shop.me.email != 'undefined'){
                this.origin_direction.email = ( this.shop.me.email != null) ? this.shop.me.email : ""
            }

            if(typeof this.shop.me.phone.number != 'undefined'){
                this.origin_direction.phone = ( this.shop.me.phone.number != null) ? this.shop.me.phone.number : ""
            }

            if(typeof this.shop.me.address.zip_code != 'undefined'){
                this.origin_direction.postal_code = ( this.shop.me.address.zip_code != null) ? this.shop.me.address.zip_code: ""
            }

            if(typeof this.shop.me.address.address != 'undefined'){
                this.origin_direction.direction_1 = ( this.shop.me.address.address != null) ? this.shop.me.address.address: ""
            }

            if(typeof this.shop.me.address.city != 'undefined'){
                this.origin_direction.city = ( this.shop.me.address.city != null) ? this.shop.me.address.city: ""
            }

            if(typeof this.shop.me.address.state != 'undefined'){
                this.origin_direction.state_code = ( this.shop.me.address.state != null) ? this.shop.me.address.state.substring(3) : ""
            }

        }else if(this.shop.config.origin_address_source == "2"){

            if(typeof this.shop.config.origin_address_full_name != 'undefined'){
                this.origin_direction.full_name = ( this.shop.config.origin_address_full_name!= null) ? this.shop.config.origin_address_full_name : ""
            }

            if(typeof this.shop.config.origin_address_email != 'undefined'){
                this.origin_direction.email = ( this.shop.config.origin_address_email!= null) ? this.shop.config.origin_address_email : ""
            }

            if(typeof this.shop.config.origin_address_phone != 'undefined'){
                this.origin_direction.phone = ( this.shop.config.origin_address_phone!= null) ? this.shop.config.origin_address_phone : ""
            }

            if(typeof this.shop.config.origin_address_postal_code != 'undefined'){
                this.origin_direction.postal_code = ( this.shop.config.origin_address_postal_code!= null) ? this.shop.config.origin_address_postal_code : ""
            }

            if(typeof this.shop.config.origin_address_direction_1 != 'undefined'){
                this.origin_direction.direction_1 = ( this.shop.config.origin_address_direction_1!= null) ? this.shop.config.origin_address_direction_1 : ""
            }
            
            if(typeof this.shop.config.origin_address_direction_2 != 'undefined'){
                this.origin_direction.direction_2 = ( this.shop.config.origin_address_direction_2!= null) ? this.shop.config.origin_address_direction_2 : ""
            }

            if(typeof this.shop.config.origin_address_city != 'undefined'){
                this.origin_direction.city = ( this.shop.config.origin_address_city!= null) ? this.shop.config.origin_address_city : ""
            }

            if(typeof this.shop.config.origin_address_state_code != 'undefined'){
                this.origin_direction.state_code = ( this.shop.config.origin_address_state_code!= null) ? this.shop.config.origin_address_state_code : ""
            }
        }
        /*
        var origin_full_name =  me.company.brand_name.trim();
        if(origin_full_name == null || origin_full_name== ""){
            origin_full_name = me.first_name+" "+me.last_name;
        }

        origin_full_name = origin_full_name.trim();
        var palabras = origin_full_name.split(/\s+/);
        if(palabras.length == 1){
            origin_full_name = origin_full_name+" SHOP";
        }
       

        this.origin_direction.full_name = origin_full_name;
        this.origin_direction.phone = me.phone.number;
        this.origin_direction.country_code = me.country_id;
        this.origin_direction.postal_code = me.address.zip_code;
        this.origin_direction.direction_1 = me.address.address;
        this.origin_direction.city = me.address.city;

        const stateIdOrigin = me.address.state
        const stateIndexOrigin = stateIdOrigin.indexOf("-");
        const stateCodeOrigin = stateIdOrigin.substr(stateIndexOrigin + 1);

        this.origin_direction.state = stateCodeOrigin;
        */
        const response = await axios.get(url,headersObj)
  
        this.mlOrder = response.data
        
        if( this.mlOrder.shipping.sender_address != null){
            this.origin_direction.neighborhood = this.mlOrder.shipping.sender_address.neighborhood.name;
        }
        

        const stateId = this.mlOrder.shipping.receiver_address.state.id
        const stateName = this.mlOrder.shipping.receiver_address.state.name
        const stateIndex = stateId.indexOf("-");
        const stateCode3 = stateId.substr(stateIndex + 1);
        const stateCode2 = this.convertirCodigoEstado(stateCode3)

        this.destination_direction.full_name = this.mlOrder.shipping.receiver_address.receiver_name
        this.destination_direction.phone = this.mlOrder.shipping.receiver_address.receiver_phone
        this.destination_direction.country_code = this.mlOrder.shipping.receiver_address.country.id
        this.destination_direction.postal_code = this.mlOrder.shipping.receiver_address.zip_code
        this.destination_direction.direction_1 = this.mlOrder.shipping.receiver_address.address_line
        this.destination_direction.city = this.mlOrder.shipping.receiver_address.city.name
        this.destination_direction.state = stateName
        this.destination_direction.state_code = stateCode2
        this.destination_direction.neighborhood = this.mlOrder.shipping.receiver_address.neighborhood.name

        if(this.destination_direction.phone == "XXXXXXX"){
            this.destination_direction.phone = "0000000000"
        }
        const parcels = []
        this.mlOrder.order_items.forEach(function(item) {
           
            const length = (item.package != null ) ? item.package.length : 0
            
            const height = ( item.package != null ) ? item.package.height : 0
            const width = ( item.package != null ) ? item.package.width : 0
            const weight = ( item.package != null ) ? item.package.weight : 0
            parcels.push({
                "quantity": item.quantity,
                "weight": weight,
                "length": length,
                "height": height,
                "width": width,
                "weight_unit": "kg",
                "content": item.item.title,
            })

        });

        this.parcels = parcels
        
    },
    data() {
        return {
            order_id:"",
            mlOrder:"",
            showPackageModal:false,
            pName:"",
            pSku:"",
            pLength:"",
            pHeight:"",
            pWidth:"",
            pWeight:"",
            rates:[],
            rateId:"",
            rating:false,
            creating:false,
            parcels:[],
            shop:"",
            origin_direction:{
                full_name:"",
                company:"",
                email:"",
                phone:"",
                postal_code:"",
                direction_1:"",
                direction_2:"",
                city:"",
                state_code:"",
                country_code:"MX",
            },
            destination_direction:{
                full_name:"",
                country_code:"",
                postal_code:"",
                direction_1:"",
                city:"",
                state:"",
                phone:"",
                state_code:"",
                neighborhood:"",
            },
            rateWarnings:[],
            rateErrors:[],
            shipmentErrors:[],
            

        }
    },
    methods:{
        getStateNameByIso(iso){

            let statesMX = {
                "AGU": "Aguascalientes",
                "BCN": "Baja California",
                "BCS": "Baja California Sur",
                "CAM": "Campeche",
                "CHP": "Chiapas",
                "CHH": "Chihuahua",
                "CMX": "Ciudad de México",
                "COA": "Coahuila",
                "COL": "Colima",
                "DUR": "Durango",
                "MEX": "Estado de México",
                "GUA": "Guanajuato",
                "GRO": "Guerrero",
                "HID": "Hidalgo",
                "JAL": "Jalisco",
                "MIC": "Michoacán",
                "MOR": "Morelos",
                "NAY": "Nayarit",
                "NLE": "Nuevo León",
                "OAX": "Oaxaca",
                "PUE": "Puebla",
                "QUE": "Querétaro",
                "ROO": "Quintana Roo",
                "SLP": "San Luis Potosí",
                "SIN": "Sinaloa",
                "SON": "Sonora",
                "TAB": "Tabasco",
                "TAM": "Tamaulipas",
                "TLA": "Tlaxcala",
                "VER": "Veracruz",
                "YUC": "Yucatán",
                "ZAC": "Zacatecas"
            }

            return statesMX[iso]

            },
        convertirCodigoEstado(codigoEstado) {

            const mapeoEstados = {
                "AGU": "AG",
                "BCN": "BC",
                "BCS": "BS",
                "CAM": "CA",
                "CHP": "CH",
                "CHH": "CI",
                "CMX": "DF",
                "COA": "CO",
                "COL": "CL",
                "DUR": "DU",
                "GUA": "GR",
                "GRO": "GR",
                "HID": "HI",
                "JAL": "JA",
                "MEX": "EM",
                "MIC": "MI",
                "MOR": "MO",
                "NAY": "NA",
                "NLE": "NL",
                "OAX": "OA",
                "PUE": "PU",
                "QUE": "QE",
                "ROO": "QR",
                "SLP": "SL",
                "SIN": "SI",
                "SON": "SO",
                "TAB": "TB",
                "TAM": "TM",
                "TLA": "TL",
                "VER": "VE",
                "YUC": "YU",
                "ZAC": "ZA"
            };
            
            const codigoUpper = codigoEstado.toUpperCase();

            if (typeof  mapeoEstados[codigoEstado] != 'undefined') {
                return mapeoEstados[codigoUpper];
            } else {
                return "Código de estado no encontrado";
            }
        },
        removeWarning(index){
            this.rateWarnings.splice(index, 1);
        },
        removeErrors(index, arr){
            this[arr].splice(index, 1);
        },
        async createShipment(){
            
            const rate = this.rates[this.rateId]

            this.creating = true

            const headersObj = {
                headers: {
                    "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                    "Content-Type": 'application/json'
                }
            }

            
            var errors = [];

            try {

                const url = process.env.VUE_APP_API_BASE_URL+'/api/auth/user'

                const response = await axios.get(url,headersObj)
                const enviaya_config = response.data.enviaya_config

                var shipmentRequest = {
                    "enviaya_account": enviaya_config.enviaya_account,
                    "api_key": (enviaya_config.api_test_mode) ? enviaya_config.api_key_dev : enviaya_config.api_key_prod,
                    "carrier": rate.carrier,
                    "carrier_service_code": rate.carrier_service_code,
                    "shipment":{
                        "shipment_type":"Package",
                        "parcels":this.parcels,
                        "file_format": "PDF",
                        "label_size": "Letter",
                        "quick_booking": true
                    },
                    "origin_direction": this.origin_direction,
                    "destination_direction": this.destination_direction,
                    "quick_booking":true,
                    "file_format":enviaya_config.file_format,
                    "label_size":enviaya_config.label_size,


                }

                
                const url2 = "https://enviaya.com.mx/api/v1/shipments";
                const apiResponse = await axios.post(url2,shipmentRequest)
                const data = apiResponse.data
                
                
                if(typeof data.errors != 'undefined'){

                    data.errors.forEach(function(errorMsg) {
                        errors.push(errorMsg)
                    });
                    this.shipmentErrors = errors
                    
                }
                
                if(errors.length == 0){

                        const url3 = process.env.VUE_APP_API_BASE_URL+'/api/mercadolibre/orders/'+this.order_id

                        const updateOrderData = {
                            "enviaya_shipment_number":data.enviaya_shipment_number,
                        }

                        var res = await axios.put(url3,updateOrderData,headersObj)
                        var resData = res.data
                        
                        const updateShipmentData = {
                            "speed":rate.est_transit_time_hours,
                            "status":"shipped",
                            "tracking_number": resData.carrier_shipment_number
                        }

                      
                        const url4 = process.env.VUE_APP_API_BASE_URL+'/api/mercadolibre/orders/'+this.order_id+'/shipments'


                        await axios.put(url4,updateShipmentData,headersObj)
                        

                        const url5 = process.env.VUE_APP_API_BASE_URL+'/api/mercadolibre/orders/'+this.order_id+'/message'
                        await axios.post(url5,updateShipmentData,headersObj)

                        location.reload();

                }
               
                
                
              
          
            } catch (error) {
                
                console.log(error)
            
                error.response.data.errors.forEach(function(errorMsg) {
                    errors.push(errorMsg)
                });
                this.shipmentErrors = errors
                
                if(errors.length == 0){
                    location.reload();
                }

                
            } finally {
                this.creating = false
            }

           


        },

        formatDimensions(item){
            return `${item.package.length}x${item.package.height}x${item.package.width}, ${item.package.weight}`
          
        },
        launchPackageModal(item){
            this.pName = item.item.title
            this.pSku = item.item.seller_sku
            this.showPackageModal = true
           
        },
        async createPackage(){

            const url = process.env.VUE_APP_API_BASE_URL+'/api/packages'

            const headersObj = {
                headers: {
                    "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                    "Content-Type": 'application/json'
                }
            }
            
            const req_data = {
                "name":this.pName,
                "sku":this.pSku,
                "length":this.pLength,
                "height":this.pHeight,
                "width":this.pWidth,
                "weight":this.pWeight
            }

            try{

                const response = await axios.post(url,req_data,headersObj)
                if(response.status == 200){
                    location.reload();
                }
              

            }catch(error){

                console.error(error.response.data.message)

            }

        },
        
        async rateShipping(){
          
            this.rating = true

            const url = process.env.VUE_APP_API_BASE_URL+'/api/auth/user'
       
            const headersObj = {
                headers: {
                    "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                    "Content-Type": 'application/json'
                }
            }

            try{

                const response = await axios.get(url,headersObj)
                const enviaya_config = response.data.enviaya_config
                
                var rateRequest = {
                    "enviaya_account": enviaya_config.enviaya_account,
                    "api_key": (enviaya_config.api_test_mode) ? enviaya_config.api_key_dev : enviaya_config.api_key_prod,
                    "shipment":{
                        "shipment_type":"Package",
                        "parcels":this.parcels
                    },
                    "origin_direction": this.origin_direction,
                    "destination_direction": this.destination_direction,
                    "currency":"MXN",
                    "timeout":15

                }
               
                const url2 = "https://enviaya.com.mx/api/v1/rates";
                const response2 = await axios.post(url2,rateRequest)
                const response2Data = response2.data
               
                for (var carrier in response2Data) {
                    if(carrier != "warning"){
                        this.rates = this.rates.concat(response2Data[carrier]);
                    }else{
                        this.rateWarnings = response2Data[carrier]
                   
                    }
                }

            }catch(error){
                console.log(error)
                var errors = [];
                Object.keys( error.response.data.errors).forEach(function(key) {
                    var errorArr = error.response.data.errors[key]
                    errorArr.forEach(function(errorMsg) {
                        errors.push(key.charAt(0).toUpperCase() + key.slice(1)+': '+errorMsg)
                    });
                });
                this.rateErrors = errors


            } finally {
                this.rating = false
            }

        },
    }
}

</script>

<style scoped>

.modal{
    display: block;
}
</style>