import { createRouter, createWebHistory } from 'vue-router';
//import Home from '../views/Home.vue';
import AdminDashboard from '../layouts/AdminDashboard.vue';
import LoginDashboard from '../layouts/LoginDashboard.vue';
import HomePage from '/src/views/HomePage.vue'
//import AboutPage from '/src/views/AboutPage.vue'
import LoginPage from '/src/views/LoginPage.vue'
import EnviayaConfig from '/src/views/Config/EnviayaConfig.vue'
import ShopsPage from '/src/views/Shops/MyShops.vue'
import ShopConfig from '/src/views/Shops/ShopConfig.vue'

import ConnectShopPage from '/src/views/ConnectShopPage.vue'
import MercadolibreConnectPage from '/src/views/MercadolibreConnectPage.vue'
import ShopOrdersPage from '/src/views/ShopOrdersPage.vue'
//import ShopOrderPage from '/src/views/ShopOrderPage.vue'   
import ShopOrderPage from '/src/views/Orders/ViewOrder.vue' 

// START PACKAGES
import PackagesPage from '/src/views/PackagesPage.vue'
import NewPackage from '/src/views/Packages/NewPackage.vue'
import EditPackage from '/src/views/Packages/EditPackage.vue'

// END PACKAGES

import auth from '../auth';



const routes = [
  {
        path:'/',
        name:'Admin',
        component:AdminDashboard,
        redirect: '/',
        meta: {
          requiresAuth: true,
        },
        children:[
            {
                path:'/',
                name:'Home',
                component:HomePage
            },
            {
              path:'/config',
              name:'EnviayaConfig',
              component:EnviayaConfig
            },
            {
              path:'/shops',
              name:'ShopsPage',
              component:ShopsPage
            },
            // START PACKAGES ROUTES
            {
              path:'/packages',
              name:'PackagesPage',
              component:PackagesPage
            },
            {
              path:'/packages/new',
              name:'NewPackage',
              component:NewPackage
            },
            {
              path:'/packages/edit/:package_id',
              name:'EditPackage',
              component:EditPackage
            },
            // END PACKAGES ROUTES
            {
              path:'/shops/:shop_id/orders',
              name:'ShopOrdersPage',
              component:ShopOrdersPage
            },
            {
              path:'/shops/:shop_id/config',
              name:'ShopConfig',
              component:ShopConfig
            },
            {
              path:'/shops/:shop_id/orders/:order_id',
              name:'ShopOrderPage',
              component:ShopOrderPage
            },
            {
              path:'/connect_shop',
              name:'ConnectShopPage',
              component:ConnectShopPage
            },
            {
              path:'/connect_shop/ml',
              name:'MercadolibreConnectPage',
              component:MercadolibreConnectPage
            },
        ]
    },
    {
      path:'/login',
      name:'Login',
      component:LoginDashboard,
      redirect: '/login/in',
      children:[
          {
              path:'/login/in',
              name:'In',
              component:LoginPage
          }
      ]
  },
] 

const router = createRouter({
  history: createWebHistory(),
  routes,
});



router.beforeEach(async (to, from, next) => {

  const isAuth = await auth.loggedIn();

  if (to.meta.requiresAuth &&  !isAuth) {
   
    next('/login');

  } else {
   
    next();
  }
});


export default router;