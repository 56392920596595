<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <span class="icon"><i class="mdi mdi-ballot"></i></span>
        Envía Ya! API
      </p>
    </header>
    <div class="columns">
      <div class="column">
        <div class="card-content">
        <form method="post" @submit.prevent="updateConfig()">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Clave API (Productivo)</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="api_key_prod" required v-on:blur="handleBlur">
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Clave API (Prueba)</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="api_key_dev"  v-on:blur="handleBlur">
                </div>

              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Modo de prueba</label></div>
            <div class="field-body">
              <div class="field">
                <label class="switch is-rounded"><input type="checkbox" v-model="api_test_mode">
                  <span class="check"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Cuenta Facturación</label></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                    <div class="select is-fullwidth" >
                      <select v-model="enviaya_account" required>
                            <option v-for="(account, index) in billing_accounts" :key="index" :value="account.account">
                              {{ account.alias }} ( {{ account.account }} )
                            </option>
                        </select>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Formato etiqueta</label></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                    <div class="select is-fullwidth" >
                      <select v-model="file_format" required>
                            <option value="pdf">PDF</option>
                            <option value="dpl">DPL</option>
                            <option value="ipl">IPL</option>
                            <option value="png">PNG</option>
                            <option value="zpl">ZPL</option>
                            <option value="epl">EPL</option>
                        </select>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Tamaño de la etiqueta</label></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                    <div class="select is-fullwidth" >
                      <select v-model="label_size" required>
                            <option value="Letter">Carta</option>
                            <option value="Thermal">Térmica</option>
                        </select>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button type="submit" class="button is-primary">
                      <span>Guardar</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      </div>
    </div>
    
  </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'EnviayaConfig',
    async created(){

        const url = process.env.VUE_APP_API_BASE_URL+'/api/auth/user'
        const headersObj = {
            headers: {
                "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                "Content-Type": 'application/json'
            }
        }
      
        try{

            const response =  await axios.get(url,headersObj);
            if(response.data.enviaya_config != null){

                this.api_key_prod = response.data.enviaya_config.api_key_prod
                this.api_key_dev = response.data.enviaya_config.api_key_dev
                this.api_test_mode = Boolean(response.data.enviaya_config.api_test_mode)
                this.enviaya_account = response.data.enviaya_config.enviaya_account
                this.file_format = response.data.enviaya_config.file_format
                this.label_size = response.data.enviaya_config.label_size

            }

            const apiKey = (this.api_test_mode) ? this.api_key_dev  : this.api_key_prod 
           
            if(apiKey != ""){

              const url2 = process.env.VUE_APP_SHIPHUK_API_BASE_URL+'/get_accounts?api_key='+apiKey
              const response2 = await axios.get(url2)
              this.billing_accounts = response2.data.enviaya_accounts

              

            }
              
             

            
            

        }catch(error){

            console.error(error.response.data.message)

        }

    

    },
    data() {
        return {
            api_test_mode: false,
            api_key_prod:"",
            api_key_dev:"",
            billing_accounts:[],
            enviaya_account:"",
            file_format:"pdf",
            label_size:"Letter"

           
        };
    },
    methods:{

      async handleBlur(){

        const apiKey = (this.api_test_mode) ? this.api_key_dev : this.api_key_prod

        const url = process.env.VUE_APP_SHIPHUK_API_BASE_URL+'/get_accounts?api_key='+apiKey
        
        try{

          const response = await axios.get(url)
          const responseData = response.data
          this.billing_accounts = responseData.enviaya_accounts

          if(this.enviaya_account == "" && this.billing_accounts.length > 0){
            this.enviaya_account = this.billing_accounts[0].account
          }
          

        }catch(error){
          console.error(error)
        }
       

       
      
      },
      async updateConfig(){
        
        const url = process.env.VUE_APP_API_BASE_URL+'/api/auth/user'

        const headersObj = {
            headers: {
                "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                "Content-Type": 'application/json'
            }
        }

        const req_data = {
          "enviaya_config":{
            "api_key_prod":this.api_key_prod,
            "api_key_dev":this.api_key_dev,
            "api_test_mode":this.api_test_mode,
            "enviaya_account":this.enviaya_account,
            "label_size":this.label_size,
            "file_format":this.file_format

          }
          
        }

        const response = await axios.put(url,req_data,headersObj)
        if(response.status == 200){
          alert('Se guardaron tus modificaciones.')
        }
      }
    }
    

}
</script>

<style scoped>
.field.is-horizontal{
  align-items: center;
}
</style>