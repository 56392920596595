<template>
    <h1>MercadolibreConnectPage</h1>
</template>

<script>

import axios from 'axios';

export default {
    name:"MercadolibeConnectPage",
    async created(){
        
        const authorization_code =  this.$route.query.code

        if(authorization_code != 'undefined'){

            try{

                const base_url = process.env.VUE_APP_API_BASE_URL
                const headersObj = {
                    headers: {
                        "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                        "Content-Type": 'application/json'
                    }
                }

                const responseShop = await axios.post(base_url+'/api/shops',{},headersObj)
                const shopId = responseShop.data.id

                const connectData = {
                    "shop_id":shopId,
                    "code":authorization_code
                }

                const responseConnect = await axios.post(base_url+'/api/mercadolibre/connect',connectData,headersObj)
                if(responseConnect.status == 200){
                    this.$router.push('/shops/'+shopId+'/orders');
                }

            }catch(error){

                console.error(error.response.data.message)

            }

            console.log(authorization_code)
        }
        
    },
    methods:{
        goTo(path){
            this.$router.push(path);
        }
    },
    components:{
        
    }
}

</script>