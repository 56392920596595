<template>
    <div class="card">
        <header class="card-header">
        <p class="card-header-title">
          <span class="icon"><i class="mdi mdi-ballot"></i></span>
          <img src="./../../assets/ml.png" style="width: 50px;"> {{ shop.name }}
        </p>
      </header>
      <div class="card-content" v-if="(typeof shop.me != 'undefined')">
        <div class="columns">
            <div class="column ">
                <nav class="panel"  style="height: 100%;" >
                    <p class="panel-heading has-text-left">
                        Usuario
                    </p>
                    <div class="block p-4">
                        <table class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                        <tbody>
                            <tr>
                                <td class="has-text-left">Usuario ID</td>
                                <td class="has-text-left">{{ shop.me.id}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Nickname </td>
                                <td class="has-text-left">{{ shop.me.nickname}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Fecha registro</td>
                                <td class="has-text-left">{{ shop.me.registration_date}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Sitio ID</td>
                                <td class="has-text-left">{{ shop.me.site_id}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> RFC</td>
                                <td class="has-text-left">{{ shop.me.identification.number}}</td>
                            </tr>

                        </tbody>
                    </table>
                    </div>
                </nav>
            </div>
            <div class="column">
                <nav class="panel"  style="height: 100%;" >
                    <p class="panel-heading has-text-left">
                        Dirección origen
                    </p>
                    <div class="block p-4">
                        <div class="control mb-4 is-flex is-justify-content-space-around	">
                            <label class="radio">
                                <input type="radio" v-model="customConfig.origin_address_source" value="1" @change="onOriginAddressSourceChange"/>
                                Domicilio de envíos ML
                            </label>
                            <label class="radio">
                                <input type="radio" v-model="customConfig.origin_address_source" value="2" @change="onOriginAddressSourceChange"/>
                                Otro Domicilio
                            </label>
                        </div>
                        <table id="origin-address-source-1" class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth" v-if="customConfig.origin_address_source=='1'">
                        <tbody>
                            <tr>
                                <td class="has-text-left">Nombre</td>
                                <td class="has-text-left">{{ origin_address_ml.full_name}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left">Compañia</td>
                                <td class="has-text-left">{{ origin_address_ml.company}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Correo electrónico</td>
                                <td class="has-text-left">{{ origin_address_ml.email}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Télefono</td>
                                <td class="has-text-left">{{ origin_address_ml.phone}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Código postal</td>
                                <td class="has-text-left">{{ origin_address_ml.postal_code}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Dirección</td>
                                <td class="has-text-left">{{ origin_address_ml.direction_1}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Ciudad</td>
                                <td class="has-text-left">{{ origin_address_ml.city}}</td>
                            </tr>
                            <tr>
                                <td class="has-text-left"> Estado</td>
                                <td class="has-text-left">{{ this.getStateNameByIso(origin_address_ml.state_code) }}</td>
                            </tr>            
         
                        </tbody>
                    </table>
                    <table id="origin-address-source-2" class="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth" v-if="customConfig.origin_address_source=='2'">
                        <tbody>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}">Nombre </td>
                                <td class="has-text-left">
                                    <input type="text" class="input" :class="{'is-normal': origin_address_submit && origin_address.full_name, 'is-danger':origin_address_submit && !origin_address.full_name}" v-model="origin_address.full_name" v-if="origin_address_editing">
                                    <span  v-else>{{origin_address.full_name}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}">Compañia </td>
                                <td class="has-text-left">
                                    <input type="text" class="input" :class="{'is-normal': origin_address_submit && origin_address.company, 'is-danger':origin_address_submit && !origin_address.company}" v-model="origin_address.company" v-if="origin_address_editing">
                                    <span  v-else>{{origin_address.company}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}"> Correo electrónico</td>
                                <td class="has-text-left">
                                    <input type="text" class="input" :class="{'is-normal': origin_address_submit && origin_address.email, 'is-danger':origin_address_submit && !origin_address.email}" v-model="origin_address.email" v-if="origin_address_editing">
                                    <span v-else>{{origin_address.email}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}"> Télefono</td>
                                <td class="has-text-left">
                                    <input type="text" class="input" :class="{'is-normal': origin_address_submit && origin_address.phone, 'is-danger':origin_address_submit && !origin_address.phone}" v-model="origin_address.phone" v-if="origin_address_editing">
                                    <span v-else>{{origin_address.phone}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}"> Código postal</td>
                                <td class="has-text-left">
                                    <input type="text" class="input" :class="{'is-normal': origin_address_submit && origin_address.postal_code, 'is-danger':origin_address_submit && !origin_address.postal_code}" v-model="origin_address.postal_code" v-if="origin_address_editing">
                                    <span v-else>{{origin_address.postal_code}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}"> Dirección</td>
                                <td class="has-text-left">
                                    <input type="text" class="input" :class="{'is-normal': origin_address_submit && origin_address.direction_1, 'is-danger':origin_address_submit && !origin_address.direction_1}" v-model="origin_address.direction_1" v-if="origin_address_editing">
                                    <span v-else>{{origin_address.direction_1}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}"> Referencias<span class="is-size-7	is-italic is-family-secondary"> (Opcional)</span></td>
                                <td class="has-text-left">
                                    <input type="text" class="input" v-model="origin_address.direction_2" v-if="origin_address_editing">
                                    <span v-else>{{origin_address.direction_2}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}"> Ciudad</td>
                                <td class="has-text-left">
                                    <input type="text" class="input" :class="{'is-normal': origin_address_submit && origin_address.city, 'is-danger':origin_address_submit && !origin_address.city}" v-model="origin_address.city" v-if="origin_address_editing">
                                    <span v-else>{{origin_address.city}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-left" :class="{'va-middle' : origin_address_editing}"> Estado</td>
                                <td class="has-text-left">
                                    <div class="select" :class="{'is-normal': origin_address_submit && origin_address.state_code, 'is-danger':origin_address_submit && !origin_address.state_code}"  style="width: 100%;" v-if="origin_address_editing">
                                        <select v-model="origin_address.state_code"   style="width: 100%;">
                                            <option value="AGU">Aguascalientes</option>
                                            <option value="BCN">Baja California</option>
                                            <option value="BCS">Baja California Sur</option>
                                            <option value="CAM">Campeche</option>
                                            <option value="CHP">Chiapas</option>
                                            <option value="CHH">Chihuahua</option>
                                            <option value="COA">Coahuila</option>
                                            <option value="COL">Colima</option>
                                            <option value="DIF">Ciudad de México</option>
                                            <option value="DUR">Durango</option>
                                            <option value="GUA">Guanajuato</option>
                                            <option value="GRO">Guerrero</option>
                                            <option value="HID">Hidalgo</option>
                                            <option value="JAL">Jalisco</option>
                                            <option value="MEX">México</option>
                                            <option value="MIC">Michoacán</option>
                                            <option value="MOR">Morelos</option>
                                            <option value="NAY">Nayarit</option>
                                            <option value="NLE">Nuevo León</option>
                                            <option value="OAX">Oaxaca</option>
                                            <option value="PUE">Puebla</option>
                                            <option value="QUE">Querétaro</option>
                                            <option value="ROO">Quintana Roo</option>
                                            <option value="SLP">San Luis Potosí</option>
                                            <option value="SIN">Sinaloa</option>
                                            <option value="SON">Sonora</option>
                                            <option value="TAB">Tabasco</option>
                                            <option value="TAM">Tamaulipas</option>
                                            <option value="TLA">Tlaxcala</option>
                                            <option value="VER">Veracruz</option>
                                            <option value="YUC">Yucatán</option>
                                            <option value="ZAC">Zacatecas</option>
                                        </select>
                                    </div>
                                    <span v-else>{{this.getStateNameByIso(origin_address.state_code)}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="pt-4 has-text-right	">
                                    <button class="button is-info"  v-if="!origin_address_editing"  @click="editOriginAddress">Editar</button>
                                    <button class="button is-success ml-1" v-if="origin_address_editing" @click="saveOriginAddress">Guardar</button>
                                    <button class="button is-danger ml-1"  v-if="origin_address_editing" @click="cancelOriginAddress" >Cancelar</button>
                                </td>
                            </tr>            
         
                        </tbody>
                    </table>
                    </div>
                </nav>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <nav class="panel"  style="height: 100%;" >
                    <p class="panel-heading has-text-left">
                        Configuración
                    </p>
                    <div class="block p-4">
                    <form method="post" @submit.prevent="updateConfig()">
                        <div class="field is-horizontal has-text-left	">
                            <div class="field-label"><label class="label">Modo de envío</label></div>
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <Multiselect
                                            v-model="customConfig.shipping_mode"
                                            :options="shipping_mode_options"
                                            :mode="'tags'"
                                        />
                                    </div>
                                    <p>Seleccione el modo de envío de los pedidos que desea sincronizar.</p>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div class="field is-horizontal has-text-left	">
                            <div class="field-label"><label class="label">Mensajería Posventa</label></div>
                            <div class="field-body">
                            <div class="field">
                                <label class="switch is-rounded"><input type="checkbox" v-model="customConfig.enabled_post_sale">
                                    <span class="check"></span>
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Mensaje</label>
                                </div>
                                <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <textarea class="input" type="text" v-model="customConfig.post_sale_msg" @input="limitarCaracteres"></textarea>
                                        <p style="text-align: left;"><strong>Variables disponibles: </strong><span style="font-style: italic;">[buyer_first_name], [buyer_last_name], [carrier_name], [tracking_number], [estimated_delivery]</span><span style="float: right;">{{customConfig.post_sale_msg.length}}/350</span></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal"></div>
                            <div class="field-body" style="text-align: left;">
                            <div class="field">
                                <div class="control">
                                <button type="submit" class="button is-primary">
                                    Guardar
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </nav>
                
            </div>
        </div>
      </div>
    </div>
    
</template>

<script>

import axios from 'axios';
import Multiselect from '@vueform/multiselect'

export default {
    async created() {
        const shopId = this.$route.params.shop_id
        this.shopId = shopId

        const url = process.env.VUE_APP_API_BASE_URL+'/api/shops/'+shopId
        const headersObj = {
            headers: {
                "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                "Content-Type": 'application/json'
            }
        }
       
        const response = await axios.get(url,headersObj)
       
        this.shop = response.data

       

        if(typeof this.shop.config.enabled_post_sale != 'undefined'){
            this.customConfig.enabled_post_sale =  Boolean(parseInt(this.shop.config.enabled_post_sale)) 

        }

        if(typeof this.shop.config.post_sale_msg != 'undefined'){
            this.customConfig.post_sale_msg =  this.shop.config.post_sale_msg
        }

        if(typeof this.shop.config.shipping_mode != 'undefined'){
            this.customConfig.shipping_mode = this.shop.config.shipping_mode.split(",");
        }else{
            this.customConfig.shipping_mode = ['me1','custom']
        }

        if(typeof this.shop.config.origin_address_source != 'undefined'){
            this.customConfig.origin_address_source = this.shop.config.origin_address_source
        }else{
            this.customConfig.origin_address_source = "1"
        }

        //START::ML ADDRESS
        if(typeof this.shop.me.first_name != 'undefined' && typeof this.shop.me.last_name != 'undefined'){
            this.origin_address_ml.full_name = ( this.shop.me.first_name != null) ? this.shop.me.first_name : ""
            this.origin_address_ml.full_name +=  ( this.shop.me.last_name != null) ? ` ${this.shop.me.last_name}` : ""
        }

        if(typeof this.shop.me.company.brand_name != 'undefined'){
            this.origin_address_ml.company = ( this.shop.me.company.brand_name != null) ? this.shop.me.company.brand_name : ""
        }

        if(typeof this.shop.me.email != 'undefined'){
            this.origin_address_ml.email = ( this.shop.me.email != null) ? this.shop.me.email : ""
        }

        if(typeof this.shop.me.phone.number != 'undefined'){
            this.origin_address_ml.phone = ( this.shop.me.phone.number != null) ? this.shop.me.phone.number : ""
        }

        if(typeof this.shop.me.address.zip_code != 'undefined'){
            this.origin_address_ml.postal_code = ( this.shop.me.address.zip_code != null) ? this.shop.me.address.zip_code: ""
        }

        if(typeof this.shop.me.address.address != 'undefined'){
            this.origin_address_ml.direction_1 = ( this.shop.me.address.address != null) ? this.shop.me.address.address: ""
        }

        if(typeof this.shop.me.address.city != 'undefined'){
            this.origin_address_ml.city = ( this.shop.me.address.city != null) ? this.shop.me.address.city: ""
        }

        if(typeof this.shop.me.address.state != 'undefined'){
            this.origin_address_ml.state_code = ( this.shop.me.address.state != null) ? this.shop.me.address.state.substring(3) : ""
        }

        //START::CUSTOM ADDRESS
       
        
        if(typeof this.shop.config.origin_address_full_name != 'undefined'){
            this.origin_address.full_name = ( this.shop.config.origin_address_full_name!= null) ? this.shop.config.origin_address_full_name : ""
        }

        if(typeof this.shop.config.origin_address_email != 'undefined'){
            this.origin_address.email = ( this.shop.config.origin_address_email!= null) ? this.shop.config.origin_address_email : ""
        }

        if(typeof this.shop.config.origin_address_phone != 'undefined'){
            this.origin_address.phone = ( this.shop.config.origin_address_phone!= null) ? this.shop.config.origin_address_phone : ""
        }

        if(typeof this.shop.config.origin_address_postal_code != 'undefined'){
            this.origin_address.postal_code = ( this.shop.config.origin_address_postal_code!= null) ? this.shop.config.origin_address_postal_code : ""
        }

        if(typeof this.shop.config.origin_address_direction_1 != 'undefined'){
            this.origin_address.direction_1 = ( this.shop.config.origin_address_direction_1!= null) ? this.shop.config.origin_address_direction_1 : ""
        }
        
        if(typeof this.shop.config.origin_address_direction_2 != 'undefined'){
            this.origin_address.direction_2 = ( this.shop.config.origin_address_direction_2!= null) ? this.shop.config.origin_address_direction_2 : ""
        }

        if(typeof this.shop.config.origin_address_city != 'undefined'){
            this.origin_address.city = ( this.shop.config.origin_address_city!= null) ? this.shop.config.origin_address_city : ""
        }

        if(typeof this.shop.config.origin_address_state_code != 'undefined'){
            this.origin_address.state_code = ( this.shop.config.origin_address_state_code!= null) ? this.shop.config.origin_address_state_code : ""
        }

        
        
        const stateId = this.shop.me.address.state
        const stateIndex = stateId.indexOf("-");
        const stateCode = stateId.substr(stateIndex + 1);
        this.stateCode = stateCode

        
    },
    data(){
        return {
            shopId:"",
            shop:{},
            shopState:"",
            shipping_mode_options: ['me1', 'me2', 'custom'],
            origin_address_submit:false,
            origin_address_editing:false,
            origin_address_ml:{
                full_name:"",
                company:"",
                email:"",
                phone:"",
                postal_code:"",
                direction_1:"",
                direction_2:"",
                city:"",
                state_code:"",
            },
            origin_address:{
                full_name:"",
                company:"",
                email:"",
                phone:"",
                postal_code:"",
                direction_1:"",
                direction_2:"",
                city:"",
                state_code:"",
            },
            customConfig:{
                origin_address_source:"",
                shipping_mode:[],
                enabled_post_sale:false,
                post_sale_msg:"Hola [buyer_first_name],\n¡Tu compra está en camino, te compartimos los detalles de tu envio:\nPaquetería: [carrier_name]\nNúmero de Rastreo: [tracking_number]\nFecha Estimada de Entrega: [estimated_delivery]"
            }
        }
       
    },
    methods:{
        async onOriginAddressSourceChange(){
            
            const url = process.env.VUE_APP_API_BASE_URL+'/api/shops/'+this.shopId+'/config'

            const headersObj = {
                headers: {
                    "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                    "Content-Type": 'application/json'
                }
            }
        
            const req_data = {
                "config":[
                    {
                        "key":"origin_address_source",
                        "value":this.customConfig.origin_address_source,
                    },
                    
                ],
            }

            const response = await axios.put(url,req_data,headersObj)
            if(response.status == 200){
                console.log('Se guardaron tus modificaciones.')
            }
            

        },
        getStateNameByIso(iso){

            let statesMX = {
                "AGU": "Aguascalientes",
                "BCN": "Baja California",
                "BCS": "Baja California Sur",
                "CAM": "Campeche",
                "CHP": "Chiapas",
                "CHH": "Chihuahua",
                "CMX": "Ciudad de México",
                "COA": "Coahuila",
                "COL": "Colima",
                "DUR": "Durango",
                "MEX": "Estado de México",
                "GUA": "Guanajuato",
                "GRO": "Guerrero",
                "HID": "Hidalgo",
                "JAL": "Jalisco",
                "MIC": "Michoacán",
                "MOR": "Morelos",
                "NAY": "Nayarit",
                "NLE": "Nuevo León",
                "OAX": "Oaxaca",
                "PUE": "Puebla",
                "QUE": "Querétaro",
                "ROO": "Quintana Roo",
                "SLP": "San Luis Potosí",
                "SIN": "Sinaloa",
                "SON": "Sonora",
                "TAB": "Tabasco",
                "TAM": "Tamaulipas",
                "TLA": "Tlaxcala",
                "VER": "Veracruz",
                "YUC": "Yucatán",
                "ZAC": "Zacatecas"
            }

            return statesMX[iso]

        },
        cancelOriginAddress(){
            this.origin_address_editing = false
            this.origin_address_submit = false
        },
        editOriginAddress(){
            this.origin_address_editing = true
        },
        async saveOriginAddress(){
            
            this.origin_address_submit = true

            let valid = true

            if( !this.origin_address.full_name || !this.origin_address.email || !this.origin_address.phone || !this.origin_address.postal_code || !this.origin_address.direction_1 || !this.origin_address.city || !this.origin_address.state_code ){
                valid = false
            }

            if(valid){

                let data = []
                for (const key in this.origin_address) {

                    data.push({
                        "key": `origin_address_${key}`,
                        "value":`${this.origin_address[key]}`
                    })

                
                }

                let url = process.env.VUE_APP_API_BASE_URL+'/api/shops/'+this.shopId+'/config'

                let headersObj = {
                    headers: {
                        "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                        "Content-Type": 'application/json'
                    }
                }
            
                let req_data = {
                    "config":data
                }

                const response = await axios.put(url,req_data,headersObj)
                if(response.status == 200){
                    alert('Se guardaron tus modificaciones.')
                }

                this.origin_address_editing = false
                this.origin_address_submit = false

            }
            


        },
        async updateConfig(){
            
            const url = process.env.VUE_APP_API_BASE_URL+'/api/shops/'+this.shopId+'/config'

            const headersObj = {
                headers: {
                    "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                    "Content-Type": 'application/json'
                }
            }
        
            const req_data = {
                "config":[
                    {
                        "key":"enabled_post_sale",
                        "value":this.customConfig.enabled_post_sale
                    },
                    {
                        "key":"post_sale_msg",
                        "value":this.customConfig.post_sale_msg
                    },
                    {
                        "key":"shipping_mode",
                        "value":this.customConfig.shipping_mode.join(",")
                    },
                    {
                        "key":"origin_address_source",
                        "value":this.customConfig.origin_address_source,
                    },
                    
                ],
            }

            const response = await axios.put(url,req_data,headersObj)
            if(response.status == 200){
                alert('Se guardaron tus modificaciones.')
            }
          

        },
        limitarCaracteres() {
            if (this.customConfig.post_sale_msg.length > 350) {
                this.customConfig.post_sale_msg= this.customConfig.post_sale_msg.slice(0, 350);
            }
        }
    },
    components: { Multiselect },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
    textarea{
        height: 300px;
    }

    .va-middle{
        vertical-align: middle;
    }


</style>