// src/auth.js
import axios from 'axios';

const auth = {
    loggedIn: async () => {
      
      var isAuth = false;
      const authToken = localStorage.getItem('authToken');
      
      
      if(authToken != null){
        
        const url = process.env.VUE_APP_API_BASE_URL+'/api/auth/user'
       
        const headersObj = {
              headers: {
                  "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                  "Content-Type": 'application/json'
              }
          }
          
          try{

            const response = await axios.get(url,headersObj)
            if(response.status == 200){
            
              isAuth=true
            }
    
          }catch(error){
            isAuth=false
          
    
          }

      }
     
      return new Promise((resolve) => {
        resolve(isAuth);
      });


      
    },
  };
  
  export default  auth;