<template>
    <div class="card has-table has-mobile-sort-spaced">
      <header class="card-header">
        <p class="card-header-title is-flex is-justify-content-space-between	">
        
          Mis tiendas
          <button @click="goTo('/connect_shop')" type="button" class="button is-primary">Conectar tienda</button>
                        
                      
        </p>
        <a href="#" class="card-header-icon">
          <span class="icon"><i class="mdi mdi-reload"></i></span>
        </a>
      </header>
      <div class="card-content">
        <div class="b-table has-pagination">
          <div class="table-wrapper has-mobile-cards">
            
            <table class="table is-striped  is-fullwidth">
              <thead>
              <tr> 
                <th>Proveedor</th>
                <th>Nombre</th>
                <th>Estado</th>
                <th >Acciones</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(shop, index) in shops" :key="index">
                  <td><img src="./../../assets/ml.png" style="width: 50px;"></td>
                  <td>{{shop.name}}</td>
                  <td><span class="tag is-success is-light">Activo</span></td>
                  <td class="is-actions-cell">
                    <div class="buttons ">
                      <button @click="goTo('/shops/'+shop.id+'/orders')" class="button is-small is-primary" type="button">
                        <span class="icon"><font-awesome-icon :icon="['fas', 'fa-eye']" /></span>
                      </button>
                      <button @click="goTo('/shops/'+shop.id+'/config')" class="button is-small is-warning" type="button">
                        <span class="icon"><font-awesome-icon :icon="['fas', 'fa-gear']" /></span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
</template>

<script>

import axios from 'axios';



export default {
    name:"MyShops",
    async created(){

        const url = process.env.VUE_APP_API_BASE_URL+'/api/shops'
        const headersObj = {
            headers: {
                "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                "Content-Type": 'application/json'
            }
        }
      
        try{

            const response = await axios.get(url,headersObj)
            this.shops = response.data
            
        }catch(error){

            console.error(error.response.data.message)

        }

    },
    data() {
        return {
            shops: [],
        };
    },
    methods:{
        goTo(path){
            this.$router.push(path);
        }
    },
    components:{
      
    }
}

</script>

<style scoped> 
.table tbody > tr > td{
  text-align: left;
  vertical-align: middle;
}

.card-content{
  min-height: 80vh;
}


</style>