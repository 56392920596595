import { createApp } from 'vue'
import App from './App.vue'

import 'bulma/css/bulma.css';
import './assets/css/main.css';

import router from './router'; // Importa la instancia de Vue Router
import store from './store'; // Importa el almacén Vuex

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";


library.add(fas)


const app = createApp(App);

app.config.globalProperties.$eshop_api_base_url = ' http://127.0.0.1:8000';
//app.config.globalProperties.$eshop_api_base_url = ' https://api.eshop.enviaya.link';

app.use(router);
app.use(store)
app.use(VueAwesomePaginate);

app.component("font-awesome-icon", FontAwesomeIcon)

app.mount('#app');

