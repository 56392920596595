<template>
    <div class="hero is-fullheight">
        <div class="hero-body is-justify-content-center is-align-items-center">
            <form @submit.prevent="login">
                <div class="columns is-flex is-flex-direction-column box">
                    <div class="column">
                        <label for="email">Email</label>
                        <input class="input is-primary" type="email" placeholder="Email address" v-model="email" required>
                    </div>
                    <div class="column">
                        <label for="Name">Password</label>
                        <input class="input is-primary" type="password" placeholder="Password" v-model="password" required>
                    
                    </div>
                    <div class="column">
                        <button class="button is-primary is-fullwidth" type="submit">Login</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

import { mapActions } from 'vuex';

export default {
    name: 'LoginPage',
    data() {
        return {
            email: '',
            password: '',
        };
    },
    created(){
        
    },
    methods:{
        ...mapActions(['setIsAuthenticated','setEmail','setToken']),
        async login(){

            const base_url = process.env.VUE_APP_API_BASE_URL

            const data = {
                email: this.email,
                password: this.password
            }

            try{

                const response = await axios.post(base_url+'/api/auth/login',data)
                this.setIsAuthenticated(true)
                this.setEmail(this.email)
                this.setToken(response.data.access_token)

                // Almacenar el token en localStorage
                localStorage.setItem('authToken', response.data.access_token);

                this.$router.push('/');
                
            }catch(error){
                console.error(error.response.data.message)
            }
           

            
           
        }

    }
}
</script>