<template>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <span class="icon"><i class="mdi mdi-ballot"></i></span>
          Nuevo Empaque
        </p>
      </header>
      <div class="card-content">
        <form method="post"  @submit.prevent="updatePackage()">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="name" required>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">SKU</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="sku" required>
                </div>
  
              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Largo (cm)</label></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="length" required>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Alto (cm)</label></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="height" required>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Ancho (cm)</label></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="width" required>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal has-text-left	">
            <div class="field-label"><label class="label">Peso (kg)</label></div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="weight" required>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button type="submit" class="button is-primary">
                      <span>Guardar</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>


<script>

import axios from 'axios';

export default {
    async created() {

      const package_id = this.$route.params.package_id

      this.package_id = package_id

      const url = process.env.VUE_APP_API_BASE_URL+'/api/packages/'+package_id

      const headersObj = {
          headers: {
              "Authorization": 'Bearer '+localStorage.getItem('authToken'),
              "Content-Type": 'application/json'
          }
      }

      const response = await axios.get(url,headersObj)

      const packageObj = response.data

      this.name = packageObj.name
      this.sku = packageObj.sku
      this.length = packageObj.length
      this.height = packageObj.height
      this.width = packageObj.width
      this.weight = packageObj.weight

     

    },
    data() {
        return {
            package_id:"",
            name: "",
            sku:"",
            length:"",
            height:"",
            width:"",
            weight:""
        }
    },
    methods:{
        async updatePackage(){
        
            const url = process.env.VUE_APP_API_BASE_URL+'/api/packages/'+this.package_id

            const headersObj = {
                headers: {
                    "Authorization": 'Bearer '+localStorage.getItem('authToken'),
                    "Content-Type": 'application/json'
                }
            }

            const req_data = {
                "name":this.name,
                "sku":this.sku,
                "length":this.length,
                "height":this.height,
                "width":this.width,
                "weight":this.weight
            }

            const response = await axios.put(url,req_data,headersObj)
            if(response.status == 200){
                alert('Se guardaron tus modificaciones.')
                this.$router.push('/packages');
            }
        }
    }
}

</script>