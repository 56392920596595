<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title"><span class="icon"><i class="mdi mdi-ballot"></i></span> Conectar una tienda
     </p>
    </header>
    <div class="card-content">
       <h1 class="has-text-left	mb-3">Plataformas de tiendas en línea disponibles</h1>
       <div class="shops-wrapper">
        <div class="block is-flex  is-align-items-center is-justify-content-space-between				">
            <div class="shop-wrapper  is-flex is-align-items-center	">
                <img src="./..//assets/ml.png" style="width: 100px;">
                <h6 class="is-size-6 has-text-weight-semibold		">Mercado Libre</h6>
                
            </div>
            <a href="https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=6040741876813197&redirect_uri=https://eshop.enviaya.link/connect_shop/ml" type="button" class="button is-primary">Conectar</a>
       </div>
       </div>
       
    </div>
</div>
</template>

<script>

export default {
    name:"ConnectShopPage",
    methods:{
        goTo(path){
            this.$router.push(path);
        }
    },
    components:{
        
    }
}

</script>

<style scoped>

.shops-wrapper > .block{
    border-bottom: 1px solid #dee2e6;
}

.shop-wrapper > h6{
    padding-left: 20px;
}
</style>