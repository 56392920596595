<template>
    <div style="padding-left: 14rem;">
        <NavEshop></NavEshop>
        <AsideEshop></AsideEshop>
        <section class="section is-main-section">
            <router-view/>
        </section>
    </div>
</template>
<script>

import NavEshop from './../components/NavEshop.vue'
import AsideEshop from './../components/AsideEshop.vue'

export default {
    name: 'DashboardEshop',
    components:{
        NavEshop,
        AsideEshop
    }
}

</script>